<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
               <b-breadcrumb-item
                v-if="$route.meta.Title == 'User Management Detail'"
                active
              >
                <span>{{userDetailName}}</span>
              </b-breadcrumb-item>
                <b-breadcrumb-item
                v-if="$route.meta.Title == 'editUser'"
                active
              >
                <span>{{userDetailName}}</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-if="$route.meta.Title == 'listingDetailPage'"
                active
              >
                <span>{{listingDetailpage}}</span>
              </b-breadcrumb-item>

               <b-breadcrumb-item
                v-if="$route.meta.Title == 'listingEditPage'"
                active
              >
                <span>{{listingDetailpage}}</span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
    <!-- <div>
      <div v-if="$route.meta.Title == 'User Management'" 
        class="top_btn_right">
          <b-button
            variant="primary"
            :to="{
              name: 'create-user',
              //params: { name: $route.params.name, id: $route.params.id },
            }"
            @click="isSpinner = false,goToCreateUserPage()"
          >
            Add User
          </b-button>
      </div>
    </div> -->
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  data(){
    return{
      userDetailName:'',
      listingDetailpage:''
    }
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  created(){
    var self = this;
    self.$root.$on('UserDetailName',(val) => {
      if(val){
        self.userDetailName = val;
      }
    })
    self.$root.$on('listingDetailPage',(val) => {
      if(val){
        self.listingDetailpage = val;
      }
    })
  },
  methods:{
    goToCreateUserPage(){
      var self = this;
      self.$router.push({name:'createUser'});
    }
  }
}
</script>
