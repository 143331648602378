export default [
  // {
  //   header: 'User Interface',
  // },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'User Management',
    route: 'user-management',
    icon: 'UsersIcon',
  },
  {
    title: 'Listing Management',
    route: 'listing-management',
    icon: 'ArchiveIcon',
  },
  {
    title: 'Application Management',
    route: 'application-management',
    icon: 'PocketIcon',
  },
  {
    title: 'Subscription Management',
    route: 'subscription-management',
    icon: 'AwardIcon',
  },
  {
    title: 'Advertisement Management',
    route: 'advertisment-management',
    icon: 'LayoutIcon',
  },
  {
    title: 'Subscription Pricing',
    route: 'subscription-pricing',
    icon: 'DollarSignIcon',
  },
  // {
  //   title: 'Purchase Agreement Upload',
  //   route: 'purchase-agreement-upload',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: '4x Customer List',
  //   route: '4x-Customer-List',
  //   icon: 'UsersIcon',
  // },
  // {
  //   title: 'Cards',
  //   icon: 'CreditCardIcon',
  //   tag: 'new',
  //   tagVariant: 'light-success',
  //   children: [
  //     {
  //       title: 'Basic',
  //       route: 'card-basic',
  //     },
  //     {
  //       title: 'Advance',
  //       route: 'card-advance',
  //     },
  //     {
  //       title: 'Statistics',
  //       route: 'card-statistic',
  //     },
  //     {
  //       title: 'Analytics',
  //       route: 'card-analytic',
  //     },
  //     {
  //       title: 'Card Actions',
  //       route: 'card-action',
  //     },
  //   ],
  // },
]
